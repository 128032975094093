import React from "react";
import ReactDOM from "react-dom/client";
import "./bob.css";
import img1 from "./imgs/rizz.gif";
import img2 from "./imgs/cuddle.gif";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

const BUTTON_OPTIONS = [
  "Are you sure?",
  "Really sure?",
  "are you like positive?",
  "pookie please",
  "just think about it",
  "if you say no i will be very sad",
  "like really sad",
  "very very very very sad",
  "okay fine :(",
  "Jk, PLEASE SAY YES",
  "you'll make pookie sad",
  "pookie is very sad now",
];

function App() {
  const [isYes, setIsYes] = React.useState(false);
  const [noCount, setNoCount] = React.useState(0);

  function handleIncreaseNoCount() {
    if (noCount === BUTTON_OPTIONS.length - 1) {
      return;
    }
    setNoCount((prev) => prev + 1);
  }

  function handleSetNoButtonText() {
    return BUTTON_OPTIONS[Math.min(noCount, BUTTON_OPTIONS.length - 1)];
  }

  return (
    <div className="app">
      <div className="container">
        <div className="app-img">
          {!isYes && (
            <img
              src={img1}
              alt="minion"
              className="app-img-src"
              style={{ top: "4rem", transform: "scale(1.5)", left: "2rem" }}
            />
          )}
          {isYes && (
            <img
              src={img2}
              alt="minion"
              className="app-img-src"
              style={{ top: "4rem", transform: "scale(0.75)", left: "-7rem" }}
            />
          )}
        </div>
        {!isYes && <div>Will you be my Valentine?</div>}
        {isYes && <div>Yay!!!</div>}
        {!isYes && (
          <div className="btn-container">
            <button
              className="btn btn-yes"
              style={{ fontSize: `${noCount * 30 + 16}px` }}
              onClick={() => setIsYes(true)}
            >
              Yes
            </button>
            <button className="btn btn-no" onClick={handleIncreaseNoCount}>
              {noCount === 0 ? "No" : handleSetNoButtonText()}
            </button>
          </div>
        )}{" "}
      </div>
    </div>
  );
}
